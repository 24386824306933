import classNames from "classnames"
import PropTypes from "prop-types"
import React, { createContext, useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"

import usePOSAccess, {
  marinaAccessProps,
} from "src/hooks/module_gate_hooks/use_pos_access"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PointOfSale from "."
import Checkout from "./Checkout"
import useCart from "./hooks/useCart"
import { useSelectedCardReader } from "./hooks/useSelectedCardReader"
import { useSelectedSoldByUser } from "./hooks/useSelectedSoldByUser"
import { formatItemForCart } from "./utils"

export const POSContext = createContext()

const Root = ({
  layouts,
  cardReaders,
  receiptPrinters,
  canManagePOS,
  marinaUsers,
  enableBluetoothCardReader,
  tipItem,
  enableTips,
  marinaAccess,
  currentUserEncodedId,
}) => {
  throw new Error("D'oh!!")
  const marinaSlug = getCurrentMarinaSlug()
  const cart = useCart()
  const [selectedSoldByUser, setSelectedSoldByUser] = useSelectedSoldByUser()
  const [selectedCardReader, setSelectedCardReader] = useSelectedCardReader()
  const { access } = usePOSAccess({ marinaSlug, initialData: marinaAccess })
  const { isLargeScreen } = useWindowSize()

  useEffect(() => {
    setSelectedSoldByUser(
      marinaUsers.find((mu) => mu.id === selectedSoldByUser?.id) ||
        marinaUsers[0]
    )
  }, [marinaUsers, selectedSoldByUser?.id, setSelectedSoldByUser])

  useEffect(() => {
    if (cardReaders.length > 0) {
      setSelectedCardReader(
        cardReaders.find((cr) => cr.id === selectedCardReader?.id) ||
          cardReaders[0]
      )
    } else {
      setSelectedCardReader({})
    }
  }, [cardReaders, selectedCardReader?.id, setSelectedCardReader])

  const router = createBrowserRouter(
    [
      {
        index: true,
        element: <PointOfSale />,
      },
      {
        path: "checkout",
        element:
          cart.fields.length === 0 ? (
            <Navigate replace to={"/"} />
          ) : (
            <Checkout
              noCardReadersConfigured={cardReaders.length === 0}
              enableBluetoothCardReader={enableBluetoothCardReader}
              enableTips={enableTips}
            />
          ),
      },
    ],
    {
      basename: `/manage/${marinaSlug}/point_of_sale`,
    }
  )

  return (
    <POSContext.Provider
      value={{
        layouts,
        cart,
        receiptPrinters,
        canManagePOS,
        marinaUsers,
        cardReaders,
        currentUserEncodedId,
        tipItem: formatItemForCart(tipItem),
        marinaAccess: access,
      }}
    >
      <FormProvider {...cart.methods}>
        <div
          className={classNames({
            "h-[calc(100dvh-65px)] px-2": isLargeScreen,
            "mt-4 h-[calc(100dvh-160px)]": !isLargeScreen,
          })}
        >
          <RouterProvider router={router} />
        </div>
      </FormProvider>
    </POSContext.Provider>
  )
}

Root.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  cardReaders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  canManagePOS: PropTypes.bool,
  marinaUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  enableBluetoothCardReader: PropTypes.bool.isRequired,
  tipItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  enableTips: PropTypes.bool.isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
  currentUserEncodedId: PropTypes.string.isRequired,
}

export default Root
