import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"

import { WizardContext } from "../../../WizardContextProvider"
import FullStayDiscountsTable from "./FullStayDiscountsTable"

const AddDiscountsButton = () => {
  const { openDiscountsModal, discountsModalDisabled, isMonthlyBilling } =
    useContext(WizardContext)
  const { watch } = useFormContext()

  const [discounts] = watch(["discounts"])

  return (
    <>
      <div className="flex flex-col">
        <p className="mb-2 font-semibold">Discounts</p>
        <Button
          variant="secondary"
          onClick={() => openDiscountsModal({ isMonthlyBilling })}
          disabled={discountsModalDisabled}
        >
          Add Discount
        </Button>
        {isMonthlyBilling ? (
          <p className="pt-2 text-gray-500">
            Discounts not available for monthly billing.
          </p>
        ) : null}
        {isMonthlyBilling && discounts.length > 0 ? (
          <div>TODO: Display Monthly discounts table</div>
        ) : (
          <FullStayDiscountsTable />
        )}
      </div>
    </>
  )
}

export default AddDiscountsButton
