import Bugsnag from "@bugsnag/js"
import PropTypes from "prop-types"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"

import ErrorFallback from "./ErrorFallback"

const ErrorBoundaryProvider = ({ children }) => {
  const ErrorBoundaryWrapper = DockwaConfig.BUGSNAG_JS_API_KEY
    ? Bugsnag.getPlugin("react").createErrorBoundary(React)
    : ErrorBoundary

  return (
    <ErrorBoundaryWrapper FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundaryWrapper>
  )
}

ErrorBoundaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ErrorBoundaryProvider
