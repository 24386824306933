import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"
import { createPortal } from "react-dom"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import ErrorBoundaryProvider from "../components/ErrorBoundaryProvider"
import ToastContainer from "../components/Toast/ToastContainer"
import { ToastProvider } from "../components/Toast/ToastContext"
import { automountableComponents } from "./automountable-components"

if (DockwaConfig.BUGSNAG_JS_API_KEY) {
  Bugsnag.start({
    apiKey: DockwaConfig.BUGSNAG_JS_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: DockwaConfig.BUGSNAG_RELEASE_STAGE,
  })
}

const setBugsnagUser = (user) => {
  if (DockwaConfig.BUGSNAG_JS_API_KEY && user) {
    Bugsnag.setUser(user.id.toString(), user.email, user.name)
  }
}

export function automountReactElements() {
  console.log(`module React v${React.version} loaded`) // eslint-disable-line no-console
  const componentNames = []

  const renderReactPortalComponents = () => {
    // Get all tags generated by the `react_component_v2` helper.
    const mountingElements = document.querySelectorAll("[data-react-component]")

    return Array.from(mountingElements).map((mountingElement) => {
      const { name, props = {} } = JSON.parse(
        mountingElement.getAttribute("data-react-component")
      )

      componentNames.push(name)

      const Component = automountableComponents[name]
      if (!Component) {
        return console.log(`Component ${name} not found`) // eslint-disable-line no-console
      } else {
        setBugsnagUser(props.bugsnagCurrentUser)
        return createPortal(<Component {...props} />, mountingElement)
      }
    })
  }

  const renderReactLayout = () => {
    const main = document.getElementById("yield")
    const layoutRoot = document.createElement("div")
    main.appendChild(layoutRoot)

    const queryClient = new QueryClient()

    ReactDOM.createRoot(layoutRoot).render(
      <ErrorBoundaryProvider>
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            {renderReactPortalComponents()}
            <div className="relative flex justify-center">
              <ToastContainer />
            </div>
            {!componentNames.includes("ChatContainer") && (
              <ReactQueryDevtools position="bottom-right" />
            )}
          </QueryClientProvider>
        </ToastProvider>
      </ErrorBoundaryProvider>
    )
  }

  document.addEventListener("DOMContentLoaded", renderReactLayout, {
    once: true,
  })
}
