import React from "react"

import Button from "src/components/Button"
import Heading from "src/components/Heading"

const MAYDAY_URL = "https://mayday.dockwa.com/en"

const ErrorFallback = () => {
  return (
    <div
      data-testid="react-error-boundary-fallback"
      className="flex w-full justify-center p-4"
    >
      <div className="flex h-full max-w-7xl flex-col items-center space-y-4 rounded border p-4">
        <Heading.ModalTitle>Something went wrong.</Heading.ModalTitle>
        <p>
          If the problem persists, please contact support at mayday@dockwa.com.
        </p>
        <a
          href={MAYDAY_URL}
          className="no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="primary">Get help</Button>
        </a>
      </div>
    </div>
  )
}

export default ErrorFallback
