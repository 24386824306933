export const friendlyPricingStructureUnitName = (pricingStructure) => {
  switch (pricingStructure) {
    case "per_day":
      return "per night"
    case "per_month":
      return "per month"
    case "per_quantity":
      return "per quantity"
    default:
      return ""
  }
}

export const friendlyInstallmentScheduleName = (schedule) => {
  switch (schedule) {
    case "upfront":
      return "Upfront"
    case "check_in":
      return "Check-in"
    case "check_out":
      return "Check-out"
    case "deposit_one_night":
      return "Deposit (one night)"
    case "deposit_quarter":
      return "Deposit (25%)"
    case "deposit_half":
      return "Deposit (50%)"
    case "monthly":
      return "Monthly"
    default:
      return ""
  }
}

export const formatFullStayDiscounts = (discounts) =>
  discounts.map(({ category, name, applyTo, discountType, amount }) => ({
    category,
    name,
    amount,
    apply_to: applyTo,
    discount_type: discountType,
  }))
