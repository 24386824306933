import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../../WizardContextProvider"
import { friendlyDiscountDisplayText } from "./helpers"

const FullStayDiscountsTable = () => {
  const { handleRemoveDiscount, discountsError } = useContext(WizardContext)
  const { watch } = useFormContext()

  const [discounts] = watch(["discounts"])
  const renderDiscount = (discount) => {
    return (
      <div
        key={discount.tempId}
        className="flex flex-row items-center justify-between space-x-2 px-1 py-2 has-[:hover]:bg-gray-100"
      >
        <span>{friendlyDiscountDisplayText(discount)}</span>
        <button
          onClick={() => handleRemoveDiscount(discount)}
          className="flex size-6 items-center justify-center rounded-full bg-transparent text-red-700 hover:bg-red-700 hover:text-white"
        >
          <i
            data-testid={`trw-remove-discount-${discount.tempId}`}
            className="icon icon-sf-x text-xs"
          />
        </button>
      </div>
    )
  }

  return (
    <div className="flex flex-col pt-4">
      {discounts.map(renderDiscount)}
      {discountsError ? <Form.Error>{discountsError}</Form.Error> : null}
    </div>
  )
}

export default FullStayDiscountsTable
