import { yupResolver } from "@hookform/resolvers/yup"
import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { createTransientReservation } from "src/api/TransientReservationWizard"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import ReservationStep from "./ReservationStep"
import ReservationStepSelector from "./ReservationStepSelector"
import AddDiscountModal from "./Steps/shared/Discounts/AddDiscountModal"
import WizardContextProvider from "./WizardContextProvider"
import { AUTOPAY_ENABLED, NO_ELECTRIC_CHOSEN_VALUE, STEPS } from "./constants"
import { schema } from "./schema"

const TransientReservationWizard = (props) => {
  const marinaSlug = getCurrentMarinaSlug()
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      arrival: parseISO(props.arrival),
      departure: props.departure ? parseISO(props.departure) : null,
      contact: props.contact,
      contact_id: props.contact?.id || null,
      contact_boat: props.boat,
      newBoat: {
        lengthOverallFeet: null,
        beamFeet: null,
      },
      contact_boat_id: props.boat?.id || null,
      storage_product_id: props.marina.defaultStorageProductId,
      billing_schedule_id: props.marina.defaultBillingScheduleId,
      electric_product_id: NO_ELECTRIC_CHOSEN_VALUE,
      payment_type: AUTOPAY_ENABLED,
      stripe_card_id: "",
      discounts: [],
    },
  })

  const { mutate: createReservation } = useMutation(
    (params) => createTransientReservation({ marinaSlug, params }),
    {
      onSuccess: (response) => console.log("success!", { response }),
      onError: (error) => console.log("error!", { error }),
    }
  )

  const onSubmit = (data) => {
    const params = {
      arrival: format(data.arrival, "yyyy-MM-dd"),
      departure: data.departure ? format(data.departure, "yyyy-MM-dd") : null,
    }
    createReservation(params)
  }

  // DISCOUNTS SECTION
  const [discounts] = methods.watch(["discounts"])
  const [discountModalOpen, setDiscountModalOpen] = useState(false)
  const [monthlyDiscountModal, setMonthlyDiscountModal] = useState(false)

  const handleOpenDiscountsModal = ({ isMonthlyBilling }) => {
    setDiscountModalOpen(true)
    setMonthlyDiscountModal(isMonthlyBilling)
  }

  const handleAddDiscount = (discount) => {
    methods.setValue("discounts", [...discounts, discount])
  }

  const handleRemoveDiscount = (discountToRemove) => {
    methods.setValue(
      "discounts",
      discounts.filter(
        (discount) => discount.tempId !== discountToRemove.tempId
      )
    )
  }
  // END DISCOUNTS SECTION

  return (
    <div data-testid="dockmaster-transient-reservation-wizard">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <WizardContextProvider
            marinaSlug={marinaSlug}
            initialStep={STEPS[props.page]}
            billingSchedules={props.marina.billingSchedules}
            storageProducts={props.marina.storageProducts}
            electricProducts={props.marina.electricProducts}
            couponCodes={props.marina.couponCodes}
            rateOptions={props.rateOptions}
            waitlistPath={props.marina.waitlistPath}
            handleOpenDiscountsModal={handleOpenDiscountsModal}
            handleRemoveDiscount={handleRemoveDiscount}
          >
            <ReservationStepSelector />
            <ReservationStep />
          </WizardContextProvider>
        </form>
      </FormProvider>
      {discountModalOpen ? (
        <AddDiscountModal
          isOpen
          monthlyDiscountModal={monthlyDiscountModal}
          onSubmit={handleAddDiscount}
          onClose={() => {
            setDiscountModalOpen(false)
          }}
        />
      ) : null}
    </div>
  )
}

export const storageProductPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export const billingSchedulePropTypes = {
  schedule: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  due_day: PropTypes.number,
  id: PropTypes.string.isRequired,
  short_term_default: PropTypes.bool.isRequired,
  long_term_default: PropTypes.bool.isRequired,
}

export const electricProductPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultPricingStructure: PropTypes.string,
}

export const ratePropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number,
  taxRate: PropTypes.number,
  mbmTranslatedPricingStructure: PropTypes.string,
}

export const couponCodePropTypes = {
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  discountText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

TransientReservationWizard.defaultProps = {
  page: "availability",
}

TransientReservationWizard.propTypes = {
  arrival: PropTypes.string.isRequired,
  departure: PropTypes.string,
  boat: PropTypes.object,
  contact: PropTypes.object,
  marina: PropTypes.shape({
    defaultBillingScheduleId: PropTypes.string.isRequired,
    billingSchedules: PropTypes.arrayOf(
      PropTypes.shape(billingSchedulePropTypes)
    ).isRequired,
    defaultStorageProductId: PropTypes.string,
    storageProducts: PropTypes.arrayOf(PropTypes.shape(storageProductPropTypes))
      .isRequired,
    electricProducts: PropTypes.arrayOf(
      PropTypes.shape(electricProductPropTypes)
    ).isRequired,
    couponCodes: PropTypes.arrayOf(PropTypes.shape(couponCodePropTypes))
      .isRequired,
    waitlistPath: PropTypes.string.isRequired,
  }),
  page: PropTypes.oneOf(Object.keys(STEPS)),
  rateOptions: PropTypes.arrayOf(PropTypes.shape(ratePropTypes)).isRequired,
}

export default TransientReservationWizard
